import React from 'react';
import ProductBoosterLandingPage from './components/ProductBoosterLandingPage';

function App() {
  return (
    <ProductBoosterLandingPage />
  );
}

export default App;
