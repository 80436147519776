import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Zoom,
    Fade,
    useMediaQuery,
    useTheme,
    createTheme,
    ThemeProvider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from '@mui/material';
  import {
    ArrowForward,
    AutoAwesome,
    ExpandMore,
    EmailOutlined,
    Check,
    CheckCircle,
    CloudUpload,
    Code,
    CompareArrows,
    CreditCard,
    Devices,
    East,
    EmojiEvents,
    FastForward,
    FileUpload,
    Image,
    KeyboardArrowUp,
    LocalOffer,
    Lock,
    Message,
    PlayArrow,
    Send,
    ShoppingCart,
    Star,
    Timeline,
    Verified,
    WhatsApp,
    ArrowBackIos,
    ArrowForwardIos,
  } from '@mui/icons-material';
  import { styled } from '@mui/system';
  


// Define a default theme to use for styled components
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#e3f2fd',
      dark: '#0d47a1',
    },
    secondary: {
      main: '#f50057',
      light: '#fce4ec',
      dark: '#c51162',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      800: '#424242',
      900: '#212121',
    },
    error: {
      main: '#f44336',
      light: '#ffebee',
    },
    success: {
      main: '#4caf50',
      light: '#e8f5e9',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
    background: {
      paper: '#ffffff',
    },
  },
  shape: {
    borderRadius: 4,
  },
  shadows: Array(25).fill('none'),
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
});

// Custom styled components
const GradientText = styled(Typography)(() => ({
  background: `linear-gradient(90deg, ${defaultTheme.palette.primary.main} 0%, ${defaultTheme.palette.secondary.main} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent',
}));

const HeroGradientBox = styled(Box)(() => ({
  background: `linear-gradient(135deg, ${defaultTheme.palette.primary.dark} 0%, ${defaultTheme.palette.primary.main} 100%)`,
  borderRadius: defaultTheme.shape.borderRadius * 2,
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url(/dots-pattern.svg) repeat',
    opacity: 0.1,
  }
}));

const BeforeAfterImage = styled(Box)(() => ({
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: defaultTheme.shape.borderRadius,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
  }
}));

const SliderDivider = styled(Box)(({ position }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: `${position}%`,
  width: '4px',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  zIndex: 2,
  cursor: 'col-resize',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const AnimatedFeatureCard = styled(Box)(() => ({
  padding: defaultTheme.spacing(3),
  borderRadius: defaultTheme.shape.borderRadius * 2,
  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.08)',
  height: '100%',
  transition: 'all 0.4s ease-in-out',
  border: '2px solid transparent',
  backgroundColor: '#ffffff',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: `linear-gradient(90deg, ${defaultTheme.palette.primary.main}, ${defaultTheme.palette.secondary.main})`,
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transition: 'transform 0.4s ease-in-out',
  },
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.12)',
    border: `2px solid ${defaultTheme.palette.grey[100]}`,
    '&::before': {
      transform: 'scaleX(1)',
    }
  }
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme ? theme.spacing(4) : 16,
  right: theme ? theme.spacing(4) : 16,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  zIndex: 1000,
}));

const ProductBoosterLandingPage = () => {
  // Inside the component, we'll use the theme from the context
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [sliderPosition, setSliderPosition] = useState(50);
  const [activeTab, setActiveTab] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [videoDialog, setVideoDialog] = useState(false);
  const [sliderPositions, setSliderPositions] = useState({
    1: 50,
    2: 50,
    3: 50
  });

  // Stats counter animation
  const [stats, setStats] = useState({
    conversionRate: 0,
    timesSaved: 0,
    happyCustomers: 0
  });

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Animate stats when in viewport
  useEffect(() => {
    const timer = setTimeout(() => {
      setStats({
        conversionRate: 40,
        timesSaved: 85,
        happyCustomers: 3578
      });
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  // Simulate image loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  const handleSliderChange = (id, newPosition) => {
    setSliderPositions({
      ...sliderPositions,
      [id]: newPosition
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage("Danke für Ihr Interesse! Wir haben Ihnen eine E-Mail mit weiteren Informationen gesendet.");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Sample before/after image pairs with improved images
  const beforeAfterImages = [
    {
      id: 1,
      before: '/assets/hero-before.jpg',
      after: '/assets/hero-after.jpg',
      title: 'Lifestyle Produkt',
      improvement: '78% höhere Klickrate'
    },
    {
      id: 2,
      before: 'https://images.unsplash.com/photo-1560343090-f0409e92791a?w=600&auto=format&fit=crop',
      after: 'https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?w=600&auto=format&fit=crop',
      title: 'Fashion Artikel',
      improvement: '62% mehr Conversions'
    },
    {
      id: 3,
      before: 'https://images.unsplash.com/photo-1512855179087-a0e079714cc9?w=600&auto=format&fit=crop',
      after: 'https://images.unsplash.com/photo-1556228578-0d85b1a4d571?w=600&auto=format&fit=crop',
      title: 'Home Decor',
      improvement: '91% besseres Engagement'
    },
  ];

  // Advanced benefits
  const benefits = [
    {
      id: 1,
      title: "Höhere Conversion-Raten",
      description: "Steigern Sie Ihre Verkaufszahlen nachweislich um bis zu 40% mit professionell aussehenden Bildern.",
      icon: <ShoppingCart fontSize="large" color="primary" />,
      stats: "+40%"
    },
    {
      id: 2,
      title: "Enorme Zeitersparnis",
      description: "Kein teures und zeitaufwändiges Fotoshooting nötig - optimieren Sie Ihre bestehenden Bilder in Minuten.",
      icon: <FastForward fontSize="large" color="primary" />,
      stats: "85%"
    },
    {
      id: 3,
      title: "Kosteneffektiv",
      description: "Sparen Sie tausende Euro für professionelle Fotografie-Equipment, Studios und Fotografen.",
      icon: <CreditCard fontSize="large" color="primary" />,
      stats: "-70%"
    },
    {
      id: 4,
      title: "Schnelle Lieferung",
      description: "Erhalten Sie Ihre optimierten Bilder innerhalb von 24 Stunden mit unserem Premium Service.",
      icon: <Timeline fontSize="large" color="primary" />,
      stats: "24h"
    },
  ];

 
// How it works steps (updated content)
const workflowSteps = [
    {
      title: "Anfrage senden",
      description: "Senden Sie uns eine Anfrage mit dem gewünschten Optimierungstyp (Amazon, Shopify, Website) und einem Link zu Ihrem Produkt oder Ihrer Website.",
      icon: <Send fontSize="large" color="primary" />
    },
    {
      title: "Muster erhalten",
      description: "Wir erstellen einige Beispielbilder für Sie, damit Sie die Qualität unserer Optimierung beurteilen können.",
      icon: <Image fontSize="large" color="primary" />
    },
    {
      title: "Angebot bestätigen",
      description: "Nach Ihrer Zustimmung erhalten Sie ein Angebot. Anschließend können Sie alle Produktbilder hochladen, die optimiert werden sollen.",
      icon: <CheckCircle fontSize="large" color="primary" />
    },
    {
      title: "Ergebnis erhalten",
      description: "In nur 1-3 Werktagen erhalten Sie Ihre optimierten Bilder oder Videos, perfekt für Amazon, Shopify oder Ihre Website.",
      icon: <AutoAwesome fontSize="large" color="primary" />
    }
  ];

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      <AppBar position="fixed" color="default" elevation={0} sx={{ 
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)' 
      }}>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="center" py={1.5}>
            <Box display="flex" alignItems="center">
              <AutoAwesome color="primary" sx={{ mr: 1 }} />
              <GradientText variant="h5" component="h1" fontWeight="bold">
                Product Booster
              </GradientText>
            </Box>
            
            {!isMobile && (
              <Box sx={{ mx: 4 }}>
                <Button color="inherit" sx={{ mx: 1 }}>Über uns</Button>
                <Button color="inherit" sx={{ mx: 1 }}>Wie es funktioniert</Button>
                <Button color="inherit" sx={{ mx: 1 }}>Preise</Button>
                <Button color="inherit" sx={{ mx: 1 }}>Kontakt</Button>
              </Box>
            )}
            
            <Box>
              {!isMobile && <Button variant="outlined" color="primary" sx={{ mr: 2 }}>Login</Button>}
              <Button 
                variant="contained" 
                color="primary" 
                endIcon={<ArrowForward />}
                sx={{ 
                  px: 3, 
                  py: 1,
                  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                }}
              >
                Kostenlos testen
              </Button>
            </Box>
          </Box>
        </Container>
      </AppBar>
      
      {/* Hero Section with animation */}
   {/* Hero Section with animation */}
<Box sx={{ pt: 12, pb: { xs: 8, md: 10 }, position: 'relative' }}>
  <Container maxWidth="lg">
    
        <Zoom in={true} timeout={1000}>
          <Box>
            <Badge 
              badgeContent={
                <Chip 
                  label="NEU" 
                  color="secondary" 
                  size="small" 
                  sx={{ 
                    fontWeight: 'bold',
                    animation: 'pulse 2s infinite'
                  }}
                />
              }
              sx={{ mb: 2 }}
            >
              <GradientText 
                variant={isMobile ? "h3" : "h2"} 
                component="h1" 
                fontWeight="bold" 
                gutterBottom
                sx={{ lineHeight: 1.2 }}
              >
                Verwandeln Sie gewöhnliche Produktfotos in Verkaufsmaschinen
              </GradientText>
            </Badge>
            
            {/* Content is now arranged horizontally on desktop */}
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {/* Left side with text and CTAs */}
              <Grid item xs={12} sm={12} md={6}>
                <Typography 
                  variant="h6" 
                  component="h2" 
                  paragraph 
                  sx={{ 
                    mb: 2,
                    color: 'text.secondary',
                    fontWeight: 400
                  }}
                >
                  Unsere KI-gestützte Plattform transformiert Ihre Standard-Produktbilder in professionell 
                  aussehende Visualisierungen, die Ihre Conversion-Rate um bis zu 40% steigern können.
                </Typography>
                
                <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{ mb: 3 }}>
                  <Button 
                    variant="contained" 
                    size="large" 
                    color="primary" 
                    sx={{ 
                      py: 1.5, 
                      px: 3,
                      fontSize: '0.9rem',
                      fontWeight: 'bold',
                      boxShadow: '0 8px 20px rgba(0, 120, 255, 0.3)',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-100%',
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
                        animation: 'shine 3s infinite',
                      },
                      '@keyframes shine': {
                        '0%': { left: '-100%' },
                        '60%': { left: '100%' },
                        '100%': { left: '100%' }
                      }
                    }}
                    startIcon={<FileUpload />}
                  >
                    Kostenlos testen
                  </Button>
                  
                  <Button 
                    variant="outlined" 
                    size="large" 
                    color="primary"
                    startIcon={<PlayArrow />}
                    onClick={() => setVideoDialog(true)}
                    sx={{ 
                      py: 1.5, 
                      px: 3,
                    }}
                  >
                    Demo ansehen
                  </Button>
                </Stack>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', mr: 2 }}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                      <Box 
                        key={index}
                        component="img"
                        src={`https://randomuser.me/api/portraits/${index % 2 ? 'women' : 'men'}/${index + 30}.jpg`}
                        alt="User"
                        sx={{ 
                          width: 28, 
                          height: 28, 
                          borderRadius: '50%', 
                          border: '2px solid white',
                          marginLeft: index > 0 ? -1.2 : 0,
                          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                        }}
                      />
                    ))}
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.85rem' }}>
                    <b>3,500+ zufriedene</b> Kunden
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
  <Zoom in={imageLoaded} timeout={800} style={{ transitionDelay: '300ms' }}>
    <Box>
      <HeroGradientBox
        sx={{
          p: 2,
          boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
          transform: isMobile ? 'none' : 'perspective(1000px) rotateY(-5deg) rotateX(5deg)',
          transition: 'transform 0.4s ease',
          '&:hover': {
            transform: isMobile ? 'none' : 'perspective(1000px) rotateY(0deg) rotateX(0deg)'
          }
        }}
      >
        <Box 
          data-slider-container="true"
          sx={{
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            position: 'relative',
            height: isMobile ? '300px' : '400px',
            boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.1)',
            cursor: 'col-resize',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            
            const container = e.currentTarget;
            
            const updatePosition = (clientX) => {
              const rect = container.getBoundingClientRect();
              let pos = ((clientX - rect.left) / rect.width) * 100;
              pos = Math.max(0, Math.min(100, pos));
              setSliderPosition(pos);
            };
            
            const handleMouseMove = (moveEvent) => {
              updatePosition(moveEvent.clientX);
            };
            
            const handleMouseUp = () => {
              document.removeEventListener('mousemove', handleMouseMove);
              document.removeEventListener('mouseup', handleMouseUp);
              document.body.style.cursor = 'default';
            };
            
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'col-resize';
            
            updatePosition(e.clientX);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            const touch = e.touches[0];
            const container = e.currentTarget;
            
            const updatePosition = (clientX) => {
              const rect = container.getBoundingClientRect();
              let pos = ((clientX - rect.left) / rect.width) * 100;
              pos = Math.max(0, Math.min(100, pos));
              setSliderPosition(pos);
            };
            
            const handleTouchMove = (moveEvent) => {
              moveEvent.preventDefault();
              updatePosition(moveEvent.touches[0].clientX);
            };
            
            const handleTouchEnd = () => {
              document.removeEventListener('touchmove', handleTouchMove);
              document.removeEventListener('touchend', handleTouchEnd);
            };
            
            document.addEventListener('touchmove', handleTouchMove, { passive: false });
            document.addEventListener('touchend', handleTouchEnd);
            
            updatePosition(touch.clientX);
          }}
        >
          <Box 
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${beforeAfterImages[0].before})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`
            }}
          >
            <Box 
              sx={{ 
                position: 'absolute',
                top: 10,
                left: 10,
                bgcolor: 'rgba(0,0,0,0.6)',
                color: 'white',
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                fontSize: '0.8rem',
                fontWeight: 'bold'
              }}
            >
              VORHER
            </Box>
          </Box>
          
          <Box 
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${beforeAfterImages[0].after})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              clipPath: `inset(0 0 0 ${sliderPosition}%)`
            }}
          >
            <Box 
              sx={{ 
                position: 'absolute',
                top: 10,
                right: 10,
                bgcolor: 'rgba(0,0,0,0.6)',
                color: 'white',
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                fontSize: '0.8rem',
                fontWeight: 'bold'
              }}
            >
              NACHHER
            </Box>
          </Box>
          
          {/* Slider divider */}
          <Box 
            data-slider-handle="true"
            sx={{ 
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: `${sliderPosition}%`,
              width: '4px',
              backgroundColor: 'white',
              transform: 'translateX(-2px)',
              cursor: 'col-resize',
              zIndex: 5
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                cursor: 'col-resize',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ color: 'grey.800', fontSize: 16, ml: 0.5 }} />
                <ArrowForwardIos sx={{ color: 'grey.800', fontSize: 16, ml: -1 }} />
              </Box>
            </Box>
          </Box>
        </Box>
        
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white', fontWeight: 500 }}>
            Bewegen Sie den Schieberegler, um den Unterschied zu sehen
          </Typography>
        </Box>
      </HeroGradientBox>
    </Box>
  </Zoom>
</Grid>

            </Grid>
        
      
      {/* Right column with main slider - VISIBLE ON MOBILE AND DESKTOP */}
     
    </Box>
    </Zoom>
  </Container>
</Box>

      {/* Statistics Section */}
      {/* <Container sx={{ py: 8, justifyContent: "center" }}>
        <Grid container spacing={4} justifyContent="center">
          {[
            { 
              label: "Durchschn. Conversion-Steigerung", 
              value: stats.conversionRate, 
              suffix: "%", 
              icon: <ShoppingCart fontSize="large" color="primary" />
            },
            { 
              label: "Zeit gespart pro Produkt", 
              value: stats.timesSaved, 
              suffix: "%", 
              icon: <FastForward fontSize="large" color="primary" />
            },
            { 
              label: "Zufriedene Kunden", 
              value: stats.happyCustomers, 
              suffix: "+", 
              icon: <EmojiEvents fontSize="large" color="primary" />
            }
          ].map((stat, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box 
                sx={{ 
                  textAlign: 'center',
                  p: 3,
                  height: '100%',
                  borderRadius: 2,
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                  bgcolor: 'background.paper',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
                  }
                }}
              >
                <Box sx={{ mb: 1 }}>
                  {stat.icon}
                </Box>
                <Typography 
                  variant="h3" 
                  color="primary.main" 
                  fontWeight="bold"
                  sx={{ mb: 1 }}
                >
                  {stat.value}{stat.suffix}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {stat.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container> */}
      
    {/* Problem/Solution Section with modern, lightweight design */}
<Box sx={{ bgcolor: 'background.paper', py: 8 }}>
  <Container>
    <Box sx={{ mb: 6, textAlign: 'center' }}>
      <Typography 
        variant="h4" 
        component="h2" 
        fontWeight="bold" 
        gutterBottom
      >
        Warum Sie bessere Produktbilder benötigen
      </Typography>
      <Typography 
        variant="body1" 
        color="text.secondary" 
        sx={{ maxWidth: 700, mx: 'auto' }}
      >
        Hochwertige Produktbilder und Videos sind entscheidend für Ihren Online-Erfolg
      </Typography>
    </Box>
    
    <Grid container spacing={4} alignItems="stretch" justifyContent="center">
      {/* PROBLEM COLUMN */}
      <Grid item xs={12} md={6}>
        <Box 
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3
            }}
          >
            <Box 
              sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                border: "1px solid",
                borderColor: 'error.light', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                mr: 2,
                flexShrink: 0
              }}
            >
              <Typography variant="subtitle1" color="error.dark">!</Typography>
            </Box>
            <Typography variant="h5" component="h3" fontWeight="medium">
              Das Problem
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />
          
          <Stack spacing={2} sx={{ mb: 'auto' }}>
            {[
              'Niedrige Conversion-Raten durch unprofessionelle Darstellung',
              'Schlechter erster Eindruck schreckt potentielle Kunden ab',
              'Hohe Kosten für professionelle Produkt-Fotografie',
              'Zeit- und Ressourcenmangel für qualitativ hochwertige Bilder',
              'Mangel an visuellen Inhalten für Social Media und Marketing'
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start'
                }}
              >
                <Box 
                  component="span" 
                  sx={{ 
                    color: 'error.main', 
                    mr: 1.5,
                    mt: 0.3,
                    flexShrink: 0
                  }}
                >
                  ✕
                </Box>
                <Typography variant="body2">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Grid>

      {/* SOLUTION COLUMN */}
      <Grid item xs={12} md={6}>
        <Box 
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: { xs: 'none', md: '1px solid' },
            borderColor: { xs: 'transparent', md: 'divider' },
            pl: { xs: 0, md: 4 }
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3
            }}
          >
            <Box 
              sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                border: "1px solid",
                borderColor: 'success.light', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                mr: 2,
                flexShrink: 0
              }}
            >
              <Typography variant="subtitle1" color="success.dark">✓</Typography>
            </Box>
            <Typography variant="h5" component="h3" fontWeight="medium">
              Unsere Lösung
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />
          
          <Stack spacing={2} sx={{ mb: 'auto' }}>
            {[
              'Professionelle Bildoptimierung steigert Ihre Conversion-Raten',
              'Hochwertiges Bildmaterial ohne teure Fotoshootings',
              'Erstellung von Produktvideos für besseres Kundenerlebnis',
              'Einheitliche Bildsprache über alle Produktkategorien hinweg',
              'Fertige Inhalte für Online-Shop, Marktplätze und Social Media'
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start'
                }}
              >
                <Box 
                  component="span" 
                  sx={{ 
                    color: 'success.main', 
                    mr: 1.5,
                    mt: 0.3,
                    flexShrink: 0
                  }}
                >
                  ✓
                </Box>
                <Typography variant="body2">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Grid>
    
    {/* Call to action */}
    <Box sx={{ textAlign: 'center', mt: 6 }}>
      <Button 
        variant="outlined" 
        color="primary"
        endIcon={<ArrowForward />}
        sx={{ borderRadius: 3 }}
      >
        Bilder optimieren lassen
      </Button>
    </Box>
  </Container>
</Box>

      
      {/* How It Works Section */}
<Container sx={{ py: 8 }}>
  <Box sx={{ mb: 6, textAlign: 'center' }}>
    <Typography
      variant="h4"
      component="h2"
      fontWeight="bold"
      color="primary.main"
      sx={{ mb: 2 }}
    >
      So einfach funktioniert es
    </Typography>
    
    <Typography
      variant="body1"
      component="p"
      color="text.secondary"
      sx={{ maxWidth: 600, mx: 'auto' }}
    >
      Von der Anfrage bis zum optimierten Produkt - in nur wenigen Schritten
    </Typography>
  </Box>
  
  <Box sx={{ position: 'relative' }}>
    {/* Connecting line */}
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
        position: 'absolute',
        top: 30,
        left: '15%',
        width: '70%',
        height: 3,
        bgcolor: 'grey.100',
        zIndex: 0
      }}
    />
    
    <Grid container spacing={3} justifyContent="center">
      {workflowSteps.map((step, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Fade in={true} style={{ transitionDelay: `${index * 150}ms` }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1
              }}
            >
              {/* Step number and icon circle */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  bgcolor: 'white',
                  boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
                  mb: 2,
                  position: 'relative',
                  border: '2px solid',
                  borderColor: 'primary.light'
                }}
              >
                {/* Step number badge */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    width: 28,
                    height: 28,
                    borderRadius: '50%',
                    bgcolor: 'primary.main',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                  }}
                >
                  {index + 1}
                </Box>
                
                {/* Icon */}
                {step.icon}
              </Box>
              
              {/* Step content */}
              <Box
                sx={{
                  textAlign: 'center',
                  maxWidth: 220,
                  mx: 'auto',
                  bgcolor: 'white',
                  py: 2,
                  px: 1,
                  borderRadius: 1,
                  height: '100%'
                }}
              >
                <Typography 
                  variant="h6" 
                  fontWeight="bold" 
                  color="primary.main"
                  sx={{ mb: 1, fontSize: '1.1rem' }}
                >
                  {step.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ fontSize: '0.9rem', lineHeight: 1.5 }}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
          </Fade>
        </Grid>
      ))}
    </Grid>
    
    {/* Simple call to action */}
    <Box sx={{ mt: 6, textAlign: 'center' }}>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        sx={{
          px: 3,
          py: 1,
          borderRadius: 2
        }}
      >
        Jetzt anfragen
      </Button>
    </Box>
  </Box>
</Container>

      {/* Before/After Gallery Section */}
{/* Before/After Gallery Section */}
<Box sx={{ bgcolor: 'grey.900', color: 'white', py: 10 }}>
  <Container>
    <Typography 
      variant="h3" 
      component="h2" 
      align="center" 
      fontWeight="bold" 
      gutterBottom
    >
      Der Product Booster Effekt
    </Typography>
    
    <Typography 
      variant="h6" 
      component="p" 
      align="center" 
      sx={{ maxWidth: 800, mx: 'auto', mb: 8, opacity: 0.9, fontWeight: 'normal' }}
    >
      Sehen Sie selbst, wie unsere Technologie gewöhnliche Produktbilder in 
      professionelle Verkaufsmagnete verwandelt
    </Typography>
    
    {/* Stack for vertical arrangement with centering */}
    <Stack 
      spacing={6} 
      alignItems="center" 
      sx={{ width: '100%' }}
    >
      {beforeAfterImages.map((image) => (
        <Box 
          key={image.id} 
          sx={{ 
            width: '100%', 
            maxWidth: '900px', // Control maximum width
            mx: 'auto'        // Center horizontally
          }}
        >
          <BeforeAfterImage 
            sx={{ 
              width: '100%',
              display: 'block', 
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Box 
              data-slider-container
              sx={{ 
                position: 'relative',
                width: '100%',
                height: { xs: 350, sm: 450, md: 550 },
                overflow: 'hidden',
                cursor: 'grab'
              }}
            >
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${image.before})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  clipPath: `inset(0 ${100 - sliderPositions[image.id]}% 0 0)`
                }}
              >
                <Box 
                  sx={{ 
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    px: 2,
                    py: 0.75,
                    borderRadius: 1,
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    fontWeight: 'bold'
                  }}
                >
                  VORHER
                </Box>
              </Box>
              
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${image.after})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  clipPath: `inset(0 0 0 ${sliderPositions[image.id]}%)`
                }}
              >
                <Box 
                  sx={{ 
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    px: 2,
                    py: 0.75,
                    borderRadius: 1,
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    fontWeight: 'bold'
                  }}
                >
                  NACHHER
                </Box>
              </Box>
              
              <SliderDivider 
                position={sliderPositions[image.id]}
                onMouseDown={(e) => {
                  const sliderContainer = e.currentTarget.closest('[data-slider-container]');
                  
                  if (!sliderContainer) {
                    console.error('Slider container not found');
                    return;
                  }
                  
                  const rect = sliderContainer.getBoundingClientRect();
                  
                  const handleMouseMove = (moveEvent) => {
                    let pos = ((moveEvent.clientX - rect.left) / rect.width) * 100;
                    pos = Math.max(0, Math.min(100, pos));
                    handleSliderChange(image.id, pos);
                  };
                  
                  const handleMouseUp = () => {
                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);
                  };
                  
                  document.addEventListener('mousemove', handleMouseMove);
                  document.addEventListener('mouseup', handleMouseUp);
                  
                  e.preventDefault();
                }}
              />
            </Box>
            
            <Box sx={{ bgcolor: 'rgba(0,0,0,0.85)', p: 3 }}>
              <Typography 
                variant="h6" 
                fontWeight="bold" 
                align="center"
                gutterBottom
              >
                {image.title}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Chip 
                  label={image.improvement} 
                  size="medium" 
                  color="secondary" 
                  sx={{ fontWeight: 500 }}
                />
              </Box>
            </Box>
          </BeforeAfterImage>
        </Box>
      ))}
    </Stack>
    
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
      <Button 
        variant="outlined" 
        color="secondary" 
        size="large"
        sx={{ 
          px: 4, 
          py: 1,
          borderRadius: 2,
          fontWeight: 'medium',
          borderWidth: 2,
          color: 'white',
          borderColor: 'secondary.main',
          '&:hover': {
            borderWidth: 2,
            backgroundColor: 'rgba(255,255,255,0.05)'
          }
        }}
      >
        Mehr Beispiele ansehen
      </Button>
    </Box>
  </Container>
</Box>



      
{/* Benefits Section with animated cards */}
<Container sx={{ py: 8 }}>
  <Typography 
    variant="h3" 
    component="h2" 
    align="center" 
    fontWeight="bold" 
    gutterBottom
  >
    Vorteile von Product Booster
  </Typography>
  
  <Typography 
    variant="h6" 
    component="p" 
    align="center" 
    color="text.secondary" 
    sx={{ maxWidth: 800, mx: 'auto', mb: 6, fontWeight: 'normal' }}
  >
    Entdecken Sie, wie wir Ihnen helfen können, mehr zu verkaufen und Zeit zu sparen
  </Typography>
  
  <Box sx={{ maxWidth: 800, mx: 'auto' }}>
    {benefits.map((benefit, index) => (
      <Box 
        key={benefit.id}
        sx={{ 
          borderBottom: '1px solid #e0e0e0',
          py: 3,
          '&:last-child': {
            borderBottom: 'none'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              mr: 2.5,
              color: 'primary.main',
              display: 'flex'
            }}
          >
            {benefit.icon}
          </Box>
          
          <Box>
            <Typography variant="h6" component="h3" fontWeight="bold">
              {benefit.title}
            </Typography>
            
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              {benefit.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
</Container>


      
{/* Call to Action Section */}
{/* Call to Action Section */}
<Box 
  sx={{ 
    bgcolor: 'grey.900', 
    color: 'white', 
    py: 10, 
    position: 'relative',
    overflow: 'hidden'
  }}
>
  <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
    <Box 
      sx={{ 
        maxWidth: 700, 
        mx: 'auto', 
        textAlign: 'center',
        px: { xs: 2, md: 4 },
        py: { xs: 4, md: 6 },
        borderRadius: 4,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(25,118,210,0.4) 0%, rgba(66,66,66,0.1) 100%)',
          borderRadius: 4,
        }
      }}
    >
      <Typography variant="h3" component="h2" fontWeight="bold" gutterBottom>
        Ihre Produktbilder professionell optimieren
      </Typography>
      
      <Typography 
        variant="h6" 
        component="p" 
        sx={{ mb: 5, maxWidth: 600, mx: 'auto', opacity: 0.9 }}
      >
        Laden Sie Ihre Produktbilder hoch und erhalten Sie innerhalb von 24 Stunden professionell 
        optimierte Bilder, die mehr Verkäufe generieren.
      </Typography>
      
      <Box 
        component="form" 
        onSubmit={handleFormSubmit}
        sx={{ 
          maxWidth: 500, 
          mx: 'auto',
          textAlign: 'left',
          bgcolor: 'rgba(0,0,0,0.2)',
          p: 4,
          borderRadius: 2
        }}
      >
        {/* Contact Information */}
        <Typography 
          variant="subtitle1" 
          fontWeight="medium" 
          sx={{ mb: 2, color: 'rgba(255,255,255,0.9)' }}
        >
          Kontaktdaten
        </Typography>
        
        <TextField 
          fullWidth 
          label="Vorname" 
          variant="outlined" 
          required
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            input: { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        <TextField 
          fullWidth 
          label="Nachname" 
          variant="outlined" 
          required
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            input: { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        <TextField 
          fullWidth 
          label="E-Mail Adresse" 
          variant="outlined" 
          type="email"
          required
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            input: { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        <TextField 
          fullWidth 
          label="Telefonnummer (optional)" 
          variant="outlined" 
          sx={{ 
            mb: 3,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            input: { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        {/* Project Details */}
        <Typography 
          variant="subtitle1" 
          fontWeight="medium" 
          sx={{ mb: 2, mt: 1, color: 'rgba(255,255,255,0.9)' }}
        >
          Optimierungsoptionen
        </Typography>
        
        <FormControl 
          fullWidth 
          variant="outlined"
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            },
            '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        >
          <InputLabel>Gewünschte Optimierung</InputLabel>
          <Select
            label="Gewünschte Optimierung"
            required
            defaultValue=""
          >
            <MenuItem value="amazon-marketplace">Amazon Marketplace-Optimierung</MenuItem>
            <MenuItem value="ebay-marketplace">eBay Marketplace-Optimierung</MenuItem>
            <MenuItem value="online-shop">Online-Shop Produktbilder</MenuItem>
            <MenuItem value="white-background">Weißer Hintergrund & Retusche</MenuItem>
            <MenuItem value="360-degree">360° Produktansicht</MenuItem>
            <MenuItem value="lifestyle">Lifestyle & Kontextbilder</MenuItem>
            <MenuItem value="ghost-mannequin">Ghost Mannequin (Kleidung)</MenuItem>
            <MenuItem value="infographic">Produktinfografiken</MenuItem>
            <MenuItem value="social-media">Social Media optimierte Bilder</MenuItem>
            <MenuItem value="custom">Individuelle Anforderungen</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl 
          fullWidth 
          variant="outlined"
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            },
            '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        >
          <InputLabel>Produktkategorie</InputLabel>
          <Select
            label="Produktkategorie"
            required
            defaultValue=""
          >
            <MenuItem value="fashion">Mode & Bekleidung</MenuItem>
            <MenuItem value="jewelry">Schmuck & Accessoires</MenuItem>
            <MenuItem value="electronics">Elektronik & Geräte</MenuItem>
            <MenuItem value="home-decor">Wohnen & Dekoration</MenuItem>
            <MenuItem value="furniture">Möbel</MenuItem>
            <MenuItem value="beauty">Beauty & Kosmetik</MenuItem>
            <MenuItem value="toys">Spielzeug & Spiele</MenuItem>
            <MenuItem value="tools">Werkzeuge & Heimwerken</MenuItem>
            <MenuItem value="food">Lebensmittel & Getränke</MenuItem>
            <MenuItem value="sports">Sport & Freizeit</MenuItem>
            <MenuItem value="other">Sonstige</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl 
          fullWidth 
          variant="outlined"
          sx={{ 
            mb: 2,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            },
            '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        >
          <InputLabel>Anzahl der Bilder</InputLabel>
          <Select
            label="Anzahl der Bilder"
            required
            defaultValue=""
          >
            <MenuItem value="1-10">1-10 Bilder</MenuItem>
            <MenuItem value="11-30">11-30 Bilder</MenuItem>
            <MenuItem value="31-50">31-50 Bilder</MenuItem>
            <MenuItem value="51-100">51-100 Bilder</MenuItem>
            <MenuItem value="100+">Mehr als 100 Bilder</MenuItem>
            <MenuItem value="ongoing">Laufende Partnerschaft</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl 
          fullWidth 
          variant="outlined"
          sx={{ 
            mb: 3,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            },
            '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        >
          <InputLabel>Bearbeitungszeitraum</InputLabel>
          <Select
            label="Bearbeitungszeitraum"
            required
            defaultValue=""
          >
            <MenuItem value="standard">Standard (3-5 Werktage)</MenuItem>
            <MenuItem value="express">Express (1-2 Werktage)</MenuItem>
            <MenuItem value="rush">Rush (24 Stunden)</MenuItem>
            <MenuItem value="super-rush">Super Rush (8 Stunden)</MenuItem>
          </Select>
        </FormControl>

        {/* Image Source Selection */}
        <Typography 
          variant="subtitle1" 
          fontWeight="medium" 
          sx={{ mb: 2, mt: 1, color: 'rgba(255,255,255,0.9)' }}
        >
          Produktbild-Quelle
        </Typography>
        
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <RadioGroup defaultValue="upload">
            <FormControlLabel 
              value="upload" 
              control={
                <Radio 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-checked': { color: 'primary.main' }
                  }} 
                />
              } 
              label="Eigene Bilder hochladen" 
              sx={{ color: 'rgba(255, 255, 255, 0.9)' }}
            />
            <FormControlLabel 
              value="url" 
              control={
                <Radio 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-checked': { color: 'primary.main' }
                  }} 
                />
              } 
              label="Bilder per URL bereitstellen" 
              sx={{ color: 'rgba(255, 255, 255, 0.9)' }}
            />
            <FormControlLabel 
              value="choose-for-me" 
              control={
                <Radio 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-checked': { color: 'primary.main' }
                  }} 
                />
              } 
              label="Beispielbilder für mich auswählen" 
              sx={{ color: 'rgba(255, 255, 255, 0.9)' }}
            />
          </RadioGroup>
        </FormControl>
        
        {/* File Upload Option */}
        <Box
          sx={{
            display: 'block', // Change to 'none' based on selected radio option
            border: '2px dashed rgba(255, 255, 255, 0.3)',
            borderRadius: 2,
            p: 3,
            textAlign: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            cursor: 'pointer',
            mb: 3,
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              borderColor: 'rgba(255, 255, 255, 0.5)',
            }
          }}
        >
          <input
            type="file"
            multiple
            accept="image/*"
            id="file-upload"
            style={{ display: 'none' }}
          />
          <label htmlFor="file-upload">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CloudUpload sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                Dateien hier ablegen oder klicken zum Hochladen
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Formate: JPG, PNG, TIFF, PSD (max. 25MB pro Datei)
              </Typography>
            </Box>
          </label>
        </Box>
        
        {/* URL Input Option */}
        <TextField 
          fullWidth 
          label="URLs zu Ihren Produktbildern (eine URL pro Zeile)" 
          variant="outlined" 
          multiline
          rows={3}
          placeholder="https://example.com/image1.jpg&#10;https://example.com/image2.jpg"
          sx={{ 
            display: 'none', // Change to 'block' based on selected radio option
            mb: 3,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& .MuiInputBase-root': { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        {/* Product Description */}
        <TextField 
          fullWidth 
          multiline
          rows={3}
          label="Beschreibung Ihres Produkts / spezielle Anforderungen" 
          variant="outlined" 
          sx={{ 
            mb: 3,
            bgcolor: 'rgba(255, 255, 255, 0.05)', 
            borderRadius: 1,
            '& .MuiInputBase-root': { color: 'white' },
            '& label': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
            }
          }}
        />
        
        <FormControlLabel
          control={
            <Checkbox 
              required
              sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                '&.Mui-checked': {
                  color: 'primary.main',
                },
              }}
            />
          }
          label={
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              Ich stimme den AGBs und Datenschutzbestimmungen zu
            </Typography>
          }
          sx={{ mb: 2 }}
        />
        
        <Button 
          fullWidth
          type="submit"
          variant="contained" 
          color="secondary" 
          size="large" 
          sx={{ 
            py: 1.8,
            fontSize: '1rem',
            fontWeight: 'bold',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)'
          }}
          endIcon={<Send />}
        >
          Anfrage absenden
        </Button>
      </Box>
      
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Lock sx={{ fontSize: 16, mr: 1, color: 'rgba(255, 255, 255, 0.6)' }} />
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          Sicher & DSGVO-konform. Ihre Daten werden geschützt.
        </Typography>
      </Box>
    </Box>
  </Container>
  
  {/* Background decoration */}
  <Box 
    sx={{ 
      position: 'absolute',
      top: -100,
      left: -100,
      width: 500,
      height: 500,
      borderRadius: '50%',
      background: 'radial-gradient(circle, rgba(25,118,210,0.4) 0%, rgba(25,118,210,0) 70%)',
    }} 
  />
  
  <Box 
    sx={{ 
      position: 'absolute',
      bottom: -150,
      right: -150,
      width: 600,
      height: 600,
      borderRadius: '50%',
      background: 'radial-gradient(circle, rgba(25,118,210,0.3) 0%, rgba(25,118,210,0) 70%)',
    }} 
  />
</Box>



      
      {/* FAQ Section */}
     {/* FAQ Section */}
<Container sx={{ py: 10 }}>
  <Typography 
    variant="h3" 
    component="h2" 
    align="center" 
    fontWeight="bold" 
    gutterBottom
    sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}
  >
    Häufig gestellte Fragen
  </Typography>
  
  <Typography 
    variant="subtitle1" 
    component="p" 
    align="center" 
    color="text.secondary" 
    sx={{ maxWidth: 600, mx: 'auto', mb: 6 }}
  >
    Alles, was Sie über unseren Produktbild-Optimierungsservice wissen müssen
  </Typography>
  
  <Box sx={{ maxWidth: 900, mx: 'auto' }}>
    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Welche Arten von Bildoptimierungen bieten Sie an?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Wir bieten eine Vielzahl an Optimierungen, darunter: Freistellung (Hintergrund entfernen), 
          professionelle Retusche, Farbkorrektur, Schatten hinzufügen, Größenanpassung, 
          Marktplatz-spezifische Optimierung (Amazon, eBay, etc.), 360°-Ansichten und Lifestyle-Compositing. 
          Jede Optimierung wird individuell nach Ihren Bedürfnissen durchgeführt.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Wie lange dauert die Bearbeitung meiner Bilder?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Unsere Standardbearbeitung erfolgt innerhalb von 3-5 Werktagen. Wenn Sie es eiliger haben,
          bieten wir Express-Services (1-2 Werktage), Rush-Service (24 Stunden) und Super-Rush (8 Stunden) 
          gegen einen Aufpreis an. Bei größeren Projekten erstellen wir Ihnen einen individuellen Zeitplan.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Warum sollte ich professionell optimierte Produktbilder verwenden?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Professionell optimierte Produktbilder können Ihre Konversionsraten um bis zu 30% steigern. 
          Hochwertige Bilder vermitteln Vertrauen, reduzieren Rücksendungen durch realistische Darstellung und 
          heben Sie von der Konkurrenz ab. Besonders auf Marktplätzen wie Amazon oder eBay, wo spezifische 
          Bildanforderungen gelten, sind optimierte Bilder ein entscheidender Erfolgsfaktor.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Welche Dateiformate und -größen werden unterstützt?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Wir akzeptieren JPG, PNG, TIFF und PSD-Dateien mit einer maximalen Größe von 25MB pro Bild. 
          Die optimierten Bilder erhalten Sie je nach Bedarf in verschiedenen Formaten und Größen, 
          optimal abgestimmt auf Ihren Verwendungszweck (Web, Druck oder spezifische Marketplace-Anforderungen).
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Was kostet der Service und gibt es Mengenrabatte?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Die Preise beginnen bei 5€ pro Bild für einfache Hintergrundentfernungen und variieren je nach 
          gewünschter Optimierung. Ja, wir bieten gestaffelte Mengenrabatte: Ab 11 Bildern sparen Sie 10%, 
          ab 31 Bildern 15% und ab 100 Bildern 20%. Für regelmäßige Aufträge empfehlen wir unsere monatlichen 
          Abonnements, die zusätzliche Einsparungen bieten.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Wie läuft der Prozess ab?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Der Prozess ist einfach: 1) Füllen Sie das Anfrageformular aus 2) Laden Sie Ihre Bilder hoch oder 
          teilen Sie URLs 3) Erhalten Sie ein kostenloses Angebot 4) Nach Ihrer Bestätigung beginnen wir mit der 
          Bearbeitung 5) Sie erhalten die optimierten Bilder innerhalb des vereinbarten Zeitrahmens 6) Sie können
          kostenlose Revisionen anfordern, falls erforderlich. Alles ist darauf ausgelegt, den Prozess für Sie 
          so unkompliziert wie möglich zu gestalten.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion 
      disableGutters 
      elevation={0} 
      sx={{ 
        mb: 1.5, 
        border: '1px solid rgba(0,0,0,0.08)', 
        borderRadius: '8px !important',
        '&:before': { display: 'none' },
        overflow: 'hidden'
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{ 
          bgcolor: 'rgba(0,0,0,0.02)',
          '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
        }}
      >
        <Typography fontWeight="medium">Was, wenn ich mit dem Ergebnis nicht zufrieden bin?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">
          Ihre Zufriedenheit hat oberste Priorität. Wenn Sie mit den Ergebnissen nicht vollständig zufrieden sind, 
          können Sie innerhalb von 7 Tagen uneingeschränkt Revisionen anfordern. Diese sind im Preis inbegriffen. 
          Sollten wir trotz Korrekturen Ihren Erwartungen nicht entsprechen können, bieten wir eine 100% Geld-zurück-Garantie.
        </Typography>
      </AccordionDetails>
    </Accordion>
  </Box>
  
  <Box 
    sx={{ 
      mt: 5, 
      textAlign: 'center',
      maxWidth: 500,
      mx: 'auto',
      px: 3,
      py: 4,
      borderRadius: 3,
      background: 'linear-gradient(135deg, rgba(25,118,210,0.05) 0%, rgba(66,66,66,0.05) 100%)',
    }}
  >
    <Typography variant="h6" fontWeight="medium" gutterBottom>
      Noch Fragen?
    </Typography>
    <Typography variant="body2" paragraph color="text.secondary">
      Unser Expertenteam steht bereit, um alle Ihre Fragen zu beantworten und Sie bei Ihrem Projekt zu unterstützen.
    </Typography>
    <Button 
      variant="outlined" 
      color="primary" 
      startIcon={<EmailOutlined />}
      sx={{ borderRadius: 2, py: 1, px: 2 }}
    >
      Kontakt aufnehmen
    </Button>
  </Box>
</Container>

      
      {/* Footer */}
      <Box 
        sx={{ 
          bgcolor: 'grey.900', 
          color: 'grey.300', 
          pt: 10, 
          pb: 4,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <AutoAwesome sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h5" component="h3" color="white" fontWeight="bold">
                  Product Booster
                </Typography>
              </Box>
              
              <Typography variant="body2" sx={{ mb: 3, maxWidth: 300 }}>
                Wir verwandeln gewöhnliche Produktfotos in professionelle Verkaufsmagnete für E-Commerce und Social Media.
              </Typography>
              
              <Box sx={{ mb: 3 }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  startIcon={<WhatsApp />}
                  size="small"
                >
                  WhatsApp Support
                </Button>
              </Box>
              
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography component="span" sx={{ fontSize: '1.5rem', mr: 1 }}>📧</Typography> 
                info@productbooster.de
              </Typography>
              
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component="span" sx={{ fontSize: '1.5rem', mr: 1 }}>📱</Typography>
                +49 (0) 123 456789
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={4} md={2}>
              <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" color="white">
                Produkt
              </Typography>
              
              {['Funktionen', 'Preise', 'Enterprise', 'Erfolgsgeschichten', 'API', 'Demo'].map((item, index) => (
                <Typography
                  key={index}
                  component="a"
                  href="#"
                  variant="body2"
                  display="block"
                  sx={{ 
                    mb: 1.5, 
                    color: 'grey.400', 
                    textDecoration: 'none',
                    transition: 'all 0.2s ease',
                    '&:hover': { color: 'primary.main', transform: 'translateX(3px)' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box 
                    component="span" 
                    sx={{ 
                      width: 6, 
                      height: 6, 
                      borderRadius: '50%', 
                      bgcolor: 'primary.main', 
                      mr: 1.5, 
                      transition: 'all 0.2s ease',
                      opacity: 0.7
                    }} 
                  />
                  {item}
                </Typography>
              ))}
            </Grid>
            
            <Grid item xs={12} sm={4} md={2}>
              <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" color="white">
                Unternehmen
              </Typography>
              
              {['Über uns', 'Team', 'Karriere', 'Blog', 'Presse', 'Partner'].map((item, index) => (
                <Typography
                  key={index}
                  component="a"
                  href="#"
                  variant="body2"
                  display="block"
                  sx={{ 
                    mb: 1.5, 
                    color: 'grey.400', 
                    textDecoration: 'none',
                    transition: 'all 0.2s ease',
                    '&:hover': { color: 'primary.main', transform: 'translateX(3px)' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box 
                    component="span" 
                    sx={{ 
                      width: 6, 
                      height: 6, 
                      borderRadius: '50%', 
                      bgcolor: 'primary.main', 
                      mr: 1.5, 
                      transition: 'all 0.2s ease',
                      opacity: 0.7
                    }} 
                  />
                  {item}
                </Typography>
              ))}
            </Grid>
            
            <Grid item xs={12} sm={4} md={2}>
              <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" color="white">
                Ressourcen
              </Typography>
              
              {['Hilfe-Center', 'Dokumentation', 'Community', 'Tutorials', 'Webinare', 'Support'].map((item, index) => (
                <Typography
                  key={index}
                  component="a"
                  href="#"
                  variant="body2"
                  display="block"
                  sx={{ 
                    mb: 1.5, 
                    color: 'grey.400', 
                    textDecoration: 'none',
                    transition: 'all 0.2s ease',
                    '&:hover': { color: 'primary.main', transform: 'translateX(3px)' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box 
                    component="span" 
                    sx={{ 
                      width: 6, 
                      height: 6, 
                      borderRadius: '50%', 
                      bgcolor: 'primary.main', 
                      mr: 1.5, 
                      transition: 'all 0.2s ease',
                      opacity: 0.7
                    }} 
                  />
                  {item}
                </Typography>
              ))}
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" color="white">
                Rechtliches
              </Typography>
              
              {['Datenschutz', 'AGB', 'Impressum', 'Cookies', 'Sicherheit'].map((item, index) => (
                <Typography
                  key={index}
                  component="a"
                  href="#"
                  variant="body2"
                  display="block"
                  sx={{ 
                    mb: 1.5, 
                    color: 'grey.400', 
                    textDecoration: 'none',
                    transition: 'all 0.2s ease',
                    '&:hover': { color: 'primary.main', transform: 'translateX(3px)' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box 
                    component="span" 
                    sx={{ 
                      width: 6, 
                      height: 6, 
                      borderRadius: '50%', 
                      bgcolor: 'primary.main', 
                      mr: 1.5, 
                      transition: 'all 0.2s ease',
                      opacity: 0.7
                    }} 
                  />
                  {item}
                </Typography>
              ))}
            </Grid>
          </Grid>
          
          <Divider sx={{ my: 6, bgcolor: 'grey.800' }} />
          
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              alignItems: { xs: 'center', sm: 'flex-start' },
              mb: 2
            }}
          >
            <Typography variant="body2" color="grey.500" sx={{ mb: { xs: 2, sm: 0 } }}>
              © {new Date().getFullYear()} Product Booster GmbH. Alle Rechte vorbehalten.
            </Typography>
            
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2,
                '& img': {
                  height: 30
                }
              }}
            >
              <Box component="img" src="https://cdn.worldvectorlogo.com/logos/paypal-2.svg" alt="PayPal" />
              <Box component="img" src="https://cdn.worldvectorlogo.com/logos/visa-2.svg" alt="Visa" />
              <Box component="img" src="https://cdn.worldvectorlogo.com/logos/mastercard-6.svg" alt="MasterCard" />
              <Box component="img" src="https://cdn.worldvectorlogo.com/logos/sofort-uberweisung.svg" alt="Sofort" />
            </Box>
          </Box>
        </Container>
        
        {/* Background decoration */}
        <Box 
          sx={{ 
            position: 'absolute',
            top: '10%',
            right: '5%',
            width: '30%',
            height: '30%',
            opacity: 0.05,
            background: 'url(/dots-pattern.svg) repeat',
            clipPath: 'circle()'
          }} 
        />
      </Box>
      
      {/* Floating elements */}
      <StyledFab 
        color="primary" 
        aria-label="scroll-to-top" 
        onClick={scrollToTop}
        sx={{ 
          display: showScrollTop ? 'flex' : 'none',
          animation: 'bounce 2s infinite'
        }}
      >
        <KeyboardArrowUp />
      </StyledFab>
      
      {/* WhatsApp chat button */}
      <Zoom in={true}>
        <Fab 
          color="success" 
          aria-label="chat" 
          sx={{ 
            position: 'fixed', 
            bottom: theme.spacing(4), 
            left: theme.spacing(4),
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          }}
        >
          <WhatsApp />
        </Fab>
      </Zoom>
      
      {/* Success message */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            bgcolor: 'success.main',
            boxShadow: 3
          }
        }}
      />
    </Box>
  );
};

export default ProductBoosterLandingPage;
